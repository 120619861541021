import React, {useState} from 'react'
import moment from 'moment/moment'
import Experience from "../components/experience";
import Education from "../components/education";
import {jobs, projects, technologies} from "../utils/data";


export default function Home() {
    const query = new URLSearchParams(window.location.search)
    const queryTech = technologies[query.get('tech')] || ''

    const [activeTab, setActiveTab] = useState(queryTech ? 'All' : 'Jobs')
    const [activeTech, setActiveTechLocal] = useState(technologies[query.get('tech')] || '');

    const setQueryTech = (tech) => {
        const search = new URLSearchParams(window.location.search)
        const tech_alias = Object.keys(technologies).find(key => technologies[key] === tech)

        if (tech === '') {
            window.history.pushState({}, '', `${window.location.pathname}`)
        } else {
            search.set('tech', tech_alias)
            window.history.pushState({}, '', `${window.location.pathname}?${search.toString()}`)
        }
    }

    const setActiveTech = (tech) => {
        if (tech === '' || activeTech === tech) {
            setActiveTechLocal('')
            setQueryTech(tech)
        } else {
            setActiveTechLocal(tech)
            setActiveTab('All')
            setQueryTech(tech)
            window.scrollTo({top: 0, behavior: 'smooth'})
        }
    }

    const totalExperience = moment().diff(jobs[jobs.length - 1].start, 'months') / 12
    const techHistoryEntries = jobs.concat(projects as any)
        .filter(entry => activeTech !== '' ? entry.technologies.indexOf(activeTech) > -1 : true)
        .sort((a, b) => moment(b.start).diff(moment(a.start)))

    const techExp = techHistoryEntries.reduce(([job, project], entry) => {
        const start = moment(entry.start)
        const end = entry.end ? moment(entry.end) : moment()
        const duration = end.diff(start, 'months') / 12

        return entry.type === 'job' ? [job + duration, project] : [job, project + duration]
    }, [0, 0])

    return (
        <>
            <div className="bg-gradient-to-r from-black to-blue-950 p-8 shadow-lg hidden"/>
            <header className="text-white p-8 font-light">
                <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
                    <div className="flex flex-col space-y-4 text-center md:text-left">
                        <h1 className="text-5xl">Duma Petrisor</h1>
                        <p className="text-xl mt-2 font-light">Fullstack Web Developer</p>
                        <p className="text-md italic">
                            With over {(totalExperience - 1).toFixed(0)} years of experience, I design and implement complex websites and mobile applications.
                        </p>
                    </div>
                </div>
            </header>
            <main className="container mx-auto md:px-6 md:py-6 px-2 py-4 bg-white shadow-lg rounded-lg ">
                <section className="mb-4 ps-2">
                    <h2 className="text-2xl font-semibold mb-4">Contact</h2>
                    <div className="flex flex-col mt-4 md:justify-start">
                        <p className="text-md">
                            <span className="font-semibold">Email: </span>
                            <a href="mailto:dumapetrisor@gmail.com" className="text-blue-900 underline hover:text-blue-90">dumapetrisor@gmail.com</a>
                        </p>
                        <p className="text-md">
                            <span className="font-semibold">Languages: </span>
                            <span className="">English, Romanian</span>
                        </p>
                    </div>
                </section>
                <span id="experience"></span>
                <div className="flex align-left">
                    <button
                        onClick={() => setActiveTab('Jobs')}
                        className={
                            `px-4 py-2 border border-blue-800 text-blue-900 mb-2 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded hover:bg-blue-900 hover:text-white cursor-pointer ${
                                activeTab === 'Jobs' ? 'bg-blue-900 text-white' : ''
                            }`
                        }
                    >
                        Professional Experience
                    </button>
                    <button
                        onClick={() => setActiveTab('Personal Projects')}
                        className={
                            `px-4 py-2 border border-blue-800 text-blue-900 mb-2 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded hover:bg-blue-900 hover:text-white cursor-pointer ${
                                activeTab === 'Personal Projects' ? 'bg-blue-900 text-white' : ''
                            }`
                        }
                        // className={`px-4 py-2 font-semibold rounded-lg transition-colors duration-300 shadow-md ${
                        //     activeTab === 'Personal Projects' ? 'bg-blue-900 text-white' : 'bg-white-200 text-black'
                        // }`}
                    >
                        Personal Projects
                    </button>
                    <button
                        onClick={() => setActiveTab('All')}
                        className={
                            `px-4 py-2 border border-blue-800 text-blue-900 mb-2 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded hover:bg-blue-900 hover:text-white cursor-pointer ${
                                activeTab === 'All' ? 'bg-blue-900 text-white' : ''
                            }`
                        }
                    >
                        All
                    </button>
                </div>
                <div>
                    {activeTech && (
                        <div className="flex justify-between items-center mb-4">
                            <div key={activeTech} className="standout-div text-blue-700 p-4 rounded-md flex flex-col w-full">
                                <div className="flex-grow">
                                    <h3 className="text-xl font-semibold">My experience with {activeTech}</h3>
                                    <p className="text-md text-gray-700">
                                        <span className="">Professional experience</span>: <span className="font-bold">{techExp[0].toFixed(1)}</span> years<br/>
                                        <span className="">Personal projects</span>: <span className="font-bold">{techExp[1].toFixed(1)}</span> years<br/>
                                        <span className="">Equivalent to a total of <span className="font-bold">{(techExp[0] + techExp[1]).toFixed(1)}</span> years of experience</span>
                                    </p>
                                </div>
                                {/*<div className="border-l-2 border-gray-300 h-full mx-4"></div>*/}
                                <button
                                    onClick={() => setActiveTech('')}
                                    className="px-4 py-2 font-semibold rounded-lg bg-white text-blue-900 hover:bg-blue-900 hover:text-white border border-blue-900 mt-2 mr-auto">
                                    Clear filter
                                </button>
                            </div>
                        </div>
                    )}

                    {activeTab === 'Jobs' && (
                        <Experience entries={jobs} selected={activeTech} setSelected={setActiveTech}/>
                    )}
                    {activeTab === 'Personal Projects' && (
                        <Experience entries={projects} selected={activeTech} setSelected={setActiveTech}/>
                    )}
                    {activeTab === 'All' && (
                        <Experience entries={techHistoryEntries} selected={activeTech} setSelected={setActiveTech}/>
                    )}
                </div>
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Education</h2>
                    <Education/>
                </section>
            </main>

            <footer className="container mx-auto mt-4 p-6 bg-white shadow-lg rounded-lg text-center">
                <p>&copy; {moment().format("YYYY")} {window.location.hostname}. All rights reserved.</p>
            </footer>
        </>
    )
}
