import moment from "moment";
import React from "react";


export default function Experience({entries, selected, setSelected}: { entries: any[], selected?: string, setSelected: (selected: string) => void }) {
    const totalYearsOfExperience = entries.reduce((acc, entry) => {
        const months = moment(entry.end || moment()).diff(entry.start, 'months')
        const diff = months / 12
        return acc + diff
    }, 0)
    return <>
        <div className="relative">
            <div className="absolute border-r-2 border-gray-200 h-full left-4 top-0"></div>

            {entries.map((entry, index) => {
                const months = moment(entry.end || moment()).diff(entry.start, 'months')
                const diff = months / 12
                return (
                    <div key={index} className="mb-6 flex items-start">
                        <div className="flex-shrink-0 w-8 flex flex-col items-center z-10">
                            <span className="bg-blue-900 h-4 w-4 rounded-full mt-1"></span>
                            <div className="h-full w-px bg-gray-200 flex-1"></div>
                        </div>
                        <div className="md:ml-6 ml-2">
                            <h3 className="text-xl font-semibold">{entry.title} {
                                entry.location && <span className="text-gray-600 text-sm">({
                                    entry.locationUrl ? <a href={entry.locationUrl} target="_blank" className="underline">{entry.location}</a> : entry.location
                                })</span>
                            }</h3>
                            <p className="text-gray-600">{[
                                entry.company,
                                [
                                    entry.start.format("MMM YYYY"),
                                    entry.end ? entry.end.format("MMM YYYY") : 'Present',
                                ].join(' - '),
                                months > 12 ? diff.toFixed(1) + ' years' : months + ' months'
                            ].join(' | ')}</p>
                            <ul className="list-disc list-inside mt-2">
                                {entry.description.map((desc, idx) => (
                                    <li key={idx}>{desc}</li>
                                ))}
                            </ul>
                            <div className="flex flex-wrap mt-2">
                                {entry.technologies.map((tech, idx) => (
                                    <span key={idx} className={[
                                        "border border-blue-800 text-blue-800 mb-2 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded hover:bg-blue-800 hover:text-white cursor-pointer",
                                        selected === tech ? 'bg-blue-800 text-white' : ''
                                    ].join(' ')} onClick={() => setSelected(tech)}>
                                        {tech}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    </>
}