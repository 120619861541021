import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import CssBaseline from '@mui/material/CssBaseline'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import 'moment-timezone'
import Home from './pages/Home'

let Wrap = process.env.NODE_ENV === 'development' ? React.StrictMode : React.Fragment

if (process.env.NODE_ENV === 'development') {
    window.localStorage.setItem('is_dev', 'true')
}

const theme = createTheme({
    palette: {
        // @ts-ignore
        black: {
            main: '#000000'
            // darker: '#053e85',
        },
        white: {
            main: '#ffffff'
            // darker: '#ffffff',
        },
        primary: {
            main: '#ee7c31'
            // darker: '#053e85',
        },
        win: {
            main: '#4caf50'
            // darker: '#053e85',
        },
        lost: {
            main: '#f44336'
            // darker: '#053e85',
        },
        pending: {
            main: '#ee7c31'
            // darker: '#053e85',
        },
        secondary: {
            // green
            main: '#00b050'
            // darker: '#053e85',
        },
        neutral: {
            main: '#ee7c31'
            // contrastText: '#fff',
        }
    }
})
Wrap = React.Fragment

// @ts-ignore
ReactDOM.createRoot(document.getElementById('root')).render(
    <Wrap>
        <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme/>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Home/>}/>
                    <Route path="*" element={<Navigate to="/"/>}/>
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    </Wrap>
)

const onPerfEntry = ({name, delta, id}: any) => {
    // @ts-ignore
    if (!window.gtag) {
        return
    }
    // @ts-ignore
    return window.gtag('event', name, {
        event_category: 'Web Vitals',
        event_label: id,
        value: Math.round(name === 'CLS' ? delta * 1000 : delta),
        non_interaction: true
    })
}
import('web-vitals').then(({getCLS, getFID, getFCP, getLCP, getTTFB}) => {
    getCLS(onPerfEntry)
    getFID(onPerfEntry)
    getFCP(onPerfEntry)
    getLCP(onPerfEntry)
    getTTFB(onPerfEntry)
})