import moment from "moment";
import React from "react";


export default function Education() {

    const entries = [
        {
            type: 'education',
            title: 'Computer Science',
            institution: 'Universitatea „Alexandru Ioan Cuza” din Iași',
            location: 'Iasi, Romania',
            start: moment('2014-12-01'),
            end: moment('2017-10-01'),
            description: [
                'Dropped out to pursue a career in software development'
            ]
        },
        {
            type: 'education',
            title: 'Bachelor\'s degree',
            institution: 'Catholic National College "Sf. Joseph" Bacău',
            location: 'Bacau, Romania',
            start: moment('2010-09-01'),
            end: moment('2014-07-01'),
            description: [
                'Graduated with a 9.33 grade'
            ]
        }
    ]


    return <>
        <div className="relative">
            <div className="absolute border-r-2 border-gray-200 h-full left-4 top-0"></div>
            {entries.map((entry, index) => {
                return (
                    <div key={index} className="mb-6 flex items-start">
                        <div className="flex-shrink-0 w-8 flex flex-col items-center z-10">
                            <span className="bg-blue-900 h-4 w-4 rounded-full mt-1"></span>
                            <div className="h-full w-px bg-gray-200 flex-1"></div>
                        </div>
                        <div className="md:ml-6 ml-2">
                            <h3 className="text-lg font-semibold">{entry.title}</h3>
                            <p className="text-gray-600">{entry.institution} | {entry.start.format('YYYY')} - {entry.end.format('YYYY')} | {entry.location}</p>
                            <ul className="list-disc list-inside mt-2">
                                {entry.description.map((desc, index) => {
                                    return <li key={index} className="text-black">{desc}</li>
                                })}
                            </ul>
                        </div>
                    </div>
                )
            })}
        </div>
    </>
}