import moment from "moment";

export const technologies = {
    php: "PHP",
    laravel: "Laravel",
    symfony: "Symfony",
    node: "Node.js",
    react: "React.js",
    rn: "React Native",
    angular: "AngularJS",
    vue: "Vue.js",
    mongo: "MongoDB",
    mysql: "MySQL",
    redis: "Redis",
    elastic: "Elasticsearch",
    docker: "Docker",
    ws: "WebSockets",
    java: "Java",
    spring: "Java Spring Boot",
    proto: "Protocol Buffers",
    tensorflow: "TensorFlow.js",
    express: "Express.js",
    nest: "Nest.js",
    nginx: "Nginx",
    rmq: "RabbitMQ",
    webrtc: "WebRTC",
    ts: "Typescript",
    fcm: "Firebase Cloud Messaging",
}

const sort = (a, b) => a.toLowerCase().localeCompare(b.toLowerCase())

export const jobs = [
    {
        type: 'job',
        title: 'Backend Engineer',
        company: '<Private company>',
        location: 'Remote',
        start: moment('2023-09-01'),
        // end: 'Present',
        end: null,
        description: [
            'Design and implement highly scalable services',
        ],
        technologies: [
            technologies.java,
            technologies.spring,
            technologies.proto,
            technologies.php,
            technologies.symfony,
            technologies.vue,
            technologies.docker,
            technologies.mongo,
            technologies.redis,
            technologies.elastic,
        ].sort(sort)
    },
    {
        type: 'job',
        title: 'Technical Architect',
        company: 'Videoslots',
        location: 'Remote',
        start: moment('2022-01-01'),
        end: moment('2023-09-01'),
        description: [
            'Designed microservices accommodating multi-brand balances and high transaction volumes',
            'Redesigned the configuration management solution to enable multi-branding, multiple domains and environments',
            'Defined the domains and the strategy for migrating the monolithic application to a microservices architecture'
        ],
        technologies: [
            technologies.php,
            technologies.docker,
            technologies.node,
            technologies.mysql,
            technologies.laravel,
            technologies.rmq,
        ].sort(sort)
    },
    {
        type: 'job',
        title: 'Senior backend developer',
        company: 'Videoslots',
        location: 'Remote',
        start: moment('2019-01-01'),
        end: moment('2022-01-01'),
        description: [
            'Developed a Laravel package to enable querying of sharded MySql databases using Eloquent',
            'Refactored the registration feature for maintainability and extensibility',
            'Upgraded the internal admin platform from Silex to Laravel framework'
        ],
        technologies: [
            technologies.php,
            technologies.docker,
            technologies.node,
            technologies.mysql,
            technologies.laravel,
            technologies.redis,
            technologies.ws,
            technologies.rmq,
        ].sort(sort)
    },
    {
        type: 'job',
        title: 'Backend developer',
        company: 'Videoslots',
        location: 'Pieta, Malta',
        start: moment('2017-10-01'),
        end: moment('2019-01-01'),
        description: [
            'Implemented CRM features to allow dynamic customer segmentation and targeted communication via email or SMS'
        ],
        technologies: [
            technologies.php,
            technologies.node,
            technologies.mysql,
            technologies.laravel,
        ].sort(sort)
    },
    {
        type: 'job',
        title: 'Web Developer',
        company: 'Mobile Touch',
        location: 'Iasi, Romania',
        start: moment('2014-12-01'),
        end: moment('2017-10-01'),
        description: [
            'Joined as a junior developer where I learned to build and ship software products',
            'At first I helped the team develop Speed Pizza(a food delivery app) and PimpMyCall(a conference call app)',
            'Then I led the development of 5 web applications which we delivered successfully'
        ],
        technologies: [
            technologies.php,
            technologies.react,
            technologies.mongo,
            technologies.angular,
            technologies.rn,
            technologies.node,
            technologies.mysql,
            technologies.laravel,
        ].sort(sort)
    }
]
export const projects = [
    {
        type: 'project',
        title: 'Autobet',
        company: 'In development',
        location: null,
        locationUrl: null,
        start: moment('2022-12-30'),
        end: null,
        description: [
            'The goal of this project is to learn Artificial Intelligence and Machine Learning.',
            'I\'m constantly trying new approaches and architectures to improve my understanding of the field.',
            'Built a scraper to collect data from various sources',
            'Implemented a data pipeline to clean and transform the data',
            'A neural network built with TensorFlow to predict the outcome of sports events',
            'I created a distributed training process using a cluster of browsers using TensorFlow.js',

            'Todo: develop a mobile application using React Native, ensuring a seamless experience across both Android and iOS platforms.',
            'Todo: integrate real-time notifications and updates in the mobile app, keeping users informed about their bets and outcomes.'
        ],
        technologies: [
            technologies.tensorflow,
            technologies.react,
            technologies.node,
            technologies.express,
            technologies.mongo,
            technologies.docker,
            technologies.nginx,
            technologies.ws,
            technologies.rmq,
        ].sort(sort)
    },
    {
        type: 'project',
        title: 'Live2020s - V2',
        company: 'Sold',
        location: 'Go to Project',
        locationUrl: 'https://live2020s.com/',
        start: moment('2021-03-01'),
        end: moment('2023-09-01'),
        description: [
            'Implemented a new design for the mobile app which was provided by a designer',
            'Refactored the backend to use a microservices architecture',
            'Optimized the backend to handle more users and data with half the resources',
        ],
        technologies: [
            technologies.react,
            technologies.rn,
            technologies.node,
            technologies.rmq,
        ].sort(sort)
    },
    {
        type: 'project',
        title: 'Live Studio',
        company: 'Sold',
        start: moment('2020-05-01'),
        end: moment('2022-12-30'),
        description: [
            'Started as a personal project to gain expertise in WebRTC, focusing on video streaming',
            'Developed the frontend using ReactJS with Typescript, following a custom design provided by a designer',
            'Implemented the backend with NodeJS and Express.js',
            'I host my own STUN/TURN server to facilitate the peer-to-peer connections',
            'The entire app is behind a Nginx reverse proxy, and the services are orchestrated with Docker and Docker Compose',
            'I dedicated my spare time(around 4 hours a day) from 2020 to 2022',
        ],
        technologies: [
            technologies.webrtc,
            technologies.node,
            technologies.ts,
            technologies.react,
            technologies.nest,
            technologies.ws,
            technologies.docker,
            technologies.rmq,
        ].sort(sort)
    },
    {
        type: 'project',
        title: 'Live2020s',
        company: 'Sold',
        location: 'Go to Project',
        locationUrl: 'https://live2020s.com/',
        start: moment('2018-01-01'),
        end: moment('2020-05-01'),
        description: [
            'Started as a personal learning endeavor to implement the Lucid Architecture on top of Laravel',
            'Laravel(with MongoDB storage) serve as the backend exposing a REST API, cached with Redis',
            'An admin panel built with React on top of Laravel Blade',
            'A NodeJS service scrapes data from various sources and stores it in MongoDB',
            'I used React.js to build the SPA frontend, with Redux for state management and Axios for API calls',
            'React Native to build the mobile application for iOS and Android',
            'The real-time chat feature is a microservice built with Node.js and Socket.io',
            'Push notifications are sent via Firebase Cloud Messaging',
            'The entire app is behind a Nginx reverse proxy, and the services are orchestrated with Docker and Docker Compose',
            'I dedicated my spare time(around 4 hours a day) from 2018 to 2020, transforming this project into a fully deployable product',
        ],
        technologies: [
            technologies.php,
            technologies.laravel,
            technologies.mongo,
            technologies.redis,
            technologies.react,
            technologies.rn,
            technologies.node,
            technologies.ws,
            technologies.nginx,
            technologies.docker,
            technologies.fcm,
        ].sort(sort)
    }
]
